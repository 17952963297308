<template>
  <div class="analysis-user-report">
    <div>
      <ai-breadcrumb />
      <div class="new-button">
        <guide
          :class="{
            hidden: group != 'global' && group != 'monthly',
          }"
          class="guide-button"
        />
      </div>
    </div>
    <div class="toolbar">
      <el-form
        class="search-form"
        :inline="true"
        size="small"
        @submit.native.prevent
      >
        <el-form-item class="search-form-gutter" :label="$t('common.keyword')">
          <el-input v-model="keyword" @change="() => fetchData()" />
        </el-form-item>
      </el-form>
      <div class="download-btn">
        <el-button
          :disabled="tableData.length === 0"
          size="small"
          class="excel-dl-btn"
          icon="el-icon-download"
          @click="downloadAll"
          >{{ $t("analysis.downloadAll") }}</el-button
        >
      </div>
    </div>
    <div class="list-content">
      <p v-if="loading">{{ $t("common.loading") }}...</p>
      <p v-else-if="tableData.length == 0">{{ $t("common.noMoreData") }}</p>
      <el-row :gutter="10" v-for="(row, idx) in tableRows" :key="idx">
        <el-col :lg="8" v-for="data in row" :key="data.id"
          ><el-image
            class="report-img"
            :src="data.thumbnailUrl"
            @click="download(data)"
          />
          <p class="title">
            {{ data.name }}
          </p>
        </el-col>
      </el-row>
    </div>
    <pagination
      v-show="total > 0"
      :total="total"
      :offset.sync="listQuery.offset"
      :limit.sync="listQuery.limit"
      layout="sizes, prev, pager, next, total "
      :pageSizes="[6, 12, 24]"
      @pagination="() => fetchData()"
    />
    <el-dialog
      :title="$t('common.downloading')"
      width="40%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :visible.sync="dialogVisible"
      :before-close="downloadClick"
    >
      <p>
        {{
          isDownloading
            ? $t("analysis.downloadProcess", {
                finishCount,
                totalCount: totalDownload,
              })
            : $t("analysis.finishDownload")
        }}
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="downloadClick">{{
          isDownloading ? $t("common.abort") : $t("common.ok")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import AiBreadcrumb from "@/components/AiBreadcrumb";
import { fetchUserReport } from "@/api/user-report";
import Pagination from "@/components/Pagination";
import { downloadFile } from "@/utils/download-file";
import Guide from "@/components/Guide";

export default {
  components: {
    AiBreadcrumb,
    Pagination,
    Guide,
  },
  data() {
    return {
      loading: false,
      keyword: "",
      tableData: [],
      listQuery: {
        offset: 0,
        limit: 6,
      },
      total: 0,
      finishCount: 0,
      totalDownload: 0,
      dialogVisible: false,
      isDownloading: false,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData(cb) {
      let query = this.listQuery;
      if (cb) {
        query = { limit: -1 };
      } else {
        this.tableData = [];
        this.loading = true;
      }
      query.q = this.keyword;
      query.group = this.group;
      fetchUserReport(query)
        .then((response) => {
          if (response.success) {
            let items = response.result.items || [];
            if (cb) {
              cb(items);
            } else {
              this.tableData = items;
              this.total = response.result.totalCount;
            }
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    download(row) {
      downloadFile(row.url, row.name);
    },
    downloadAll() {
      this.fetchData((res) => {
        this.isDownloading = true;
        this.dialogVisible = true;
        this.totalDownload = res.length;
        const doDownload = (i) => {
          if (this.isDownloading && res.length > i) {
            this.finishCount = i;
            downloadFile(res[i].url, res[i].name, () => {
              doDownload(i + 1);
            });
          } else {
            this.finishCount = 0;
            this.isDownloading = false;
          }
        };
        doDownload(0);
      });
    },
    downloadClick() {
      this.isDownloading = false;
      this.dialogVisible = false;
      this.finishCount = 0;
    },
  },
  computed: {
    group() {
      if (!this.$route.meta) {
        return null;
      }
      return this.$route.meta["type"];
    },
    tableRows() {
      let v = [];
      this.tableData.forEach((e, i) => {
        if (i % 3 === 0) {
          v.push([e]);
        } else {
          v[parseInt(i / 3)].push(e);
        }
      });
      return v;
    },
  },
};
</script>

<style lang="scss" scoped>
.analysis-user-report {
  .new-button {
    text-align: right;
    width: 100%;
    margin: 10px 0;

    .guide-button.hidden {
      visibility: hidden;
    }
  }
  .toolbar {
    background-color: #fff;
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
    text-align: right;

    .search-form {
      float: left;
    }

    .download-btn {
      display: inline-block;
    }
  }

  .list-content {
    text-align: center;
    font-size: 14px;
    color: #606266;

    .report-img {
      cursor: pointer;
    }

    .title {
      margin-top: 5px;
      margin-bottom: 10px;
      line-height: 27px;
    }
  }
}
</style>